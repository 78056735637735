<template>
  <div class="ps-lg-7 pt-4">
    <h1 class="fs-24 fw-700 opacity-80 mb-5 mt-3">{{ $t('my_wallet') }}</h1>
    <v-row class="mb-4">
      <v-col cols="12" xl="9" sm="6">
        <v-sheet
          color="grey darken-3"
          rounded="rounded"
          elevation="1"
          height="150"
          class="d-flex justify-center align-center white--text flex-column"
        >
          <div class="fs-16 mb-3">{{ $t('wallet_balance') }}</div>
          <div class="fw-300 display-2">
            {{ new_format_price(currentUser.balance) }}
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="6" xl="3">
        <v-sheet
          color="grey darken-1"
          rounded="rounded"
          elevation="1"
          height="150"
          class="d-flex justify-center align-center white--text flex-column c-pointer"
          @click.stop="isVisible = true"
        >
          <div class="fs-16 mb-3">{{ $t('recharge_wallet') }}</div>
          <div class="fw-300 display-2">+</div>
        </v-sheet>
      </v-col>
    </v-row>
    <h2 class="fs-20 fw-700 opacity-80 mb-4">{{ $t('wallet_history') }}</h2>
    <div>
      <v-data-table
        :headers="headers"
        :items="rechargeHistories"
        class="border px-4 pt-3"
        :loading-text="$t('loading_please_wait')"
        hide-default-footer
        :loading="loading"
      >
        <template v-slot:[`item.date`]="{ item }">
          <span class="fw-600">{{ item.date }}</span>
        </template>

        <template v-slot:[`item.order_num`]="{ item }">
          <span class="fw-600">{{ item.order_num }}</span>
        </template>

        <!-- 金额 -->
        <template v-slot:[`item.amount`]="{ item }">
          <span class="fw-600">{{ new_format_price(item.amount) }}</span>
        </template>

        <!-- 细节 -->
        <template v-slot:[`item.details`]="{ item }">
          <span class="fw-600">
            <!-- {{ item.details }} -->
            <span v-if="item.payment_method">{{ item.payment_method }}</span>
          </span>
          <!-- <span class="fw-600" v-if="item.details == 'Recharge'">
            {{ item.details }}
            <span v-if="item.payment_method">({{ item.payment_method }})</span>
          </span>
          <span class="fw-600" v-else>
            {{ item.details }}
            <a :href="item.receipt" target="_blank" v-if="item.receipt">({{ $t('receipt') }})</a>
          </span> -->
        </template>

        <!-- 类型 -->
        <template v-slot:[`item.type`]="{ item }">
          <v-btn
            x-small
            :color="approvalStatusColor(item.approval)"
            style="color: #f5f5f5"
          >
            {{ approvalStatusText(item.approval) }}
          </v-btn>
          <!-- <v-btn v-if="item.type == 'Added'" x-small color="success" elevation="0">
            {{ $t('Added') }}
          </v-btn>

          <v-btn
            v-else-if="item.type == 'Deducted' || item.type == 'Cancelled'"
            x-small
            color="error"
            elevation="0"
          >
            {{ item.type }}
          </v-btn>

          <v-btn v-else x-small color="warning" elevation="0">
            {{ item.type }}
          </v-btn> -->
        </template>

        <template v-slot:[`item.refuse_reason`]="{ item }">
          <span class="fw-600">{{ item.refuse_reason }}</span>
        </template>
      </v-data-table>

      <div class="text-center" v-if="totalPages > 1">
        <v-pagination
          v-model="currentPage"
          @input="getList"
          :length="totalPages"
          prev-icon="la-angle-left"
          next-icon="la-angle-right"
          :total-visible="7"
          elevation="0"
          class="my-4"
        ></v-pagination>
      </div>
    </div>

    <!-- 充值弹窗 -->
    <recharge-dialog
      :show="isVisible"
      :paymentMethods="paymentMethods"
      from="/user/wallet"
      @onClose="onClose"
      @onRefresh="onRefresh"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import RechargeDialog from '../../components/wallet/RechargeDialog.vue'

export default {
  data: () => ({
    isVisible: false,
    loading: true,
    currentPage: 1,
    totalPages: 1,
    rechargeHistories: [],
    html: null
  }),

  components: {
    RechargeDialog
  },

  computed: {
    ...mapState('wallet', ['paymentMethods']),
    ...mapGetters('auth', ['currentUser']),

    headers() {
      return [
        {
          text: this.$i18n.t('date'),
          align: 'start',
          sortable: false,
          value: 'date'
        },
        {
          text: this.$i18n.t('order_number'),
          align: 'start',
          sortable: false,
          value: 'order_num'
        },
        {
          text: this.$i18n.t('amount'),
          sortable: false,
          value: 'amount'
        },
        {
          text: this.$i18n.t('details'),
          sortable: false,
          value: 'details'
        },
        {
          text: this.$i18n.t('status'),
          sortable: false,
          align: 'center',
          value: 'type'
        },
        {
          text: this.$i18n.t('remark'),
          sortable: false,
          align: 'center',
          value: 'refuse_reason'
        }
      ]
    }
  },

  async created() {
    const page = this.$route.query.page || this.currentPage
    this.getList(page)
    this.fetchRechargeMethods()

    // 获取用户信息(余额)
    this.getUser()
  },

  mounted() {
    this.rechargeWallet(this.$route.query.wallet_payment)
  },

  watch: {
    currentPage() {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            page: this.currentPage
          }
        })
        .catch(() => {})
    }
  },

  methods: {
    ...mapActions('auth', ['rechargeWallet', 'getUser']),
    ...mapActions('wallet', ['fetchRechargeMethods']),

    async getList(number) {
      this.loading = true
      try {
        const res = await this.call_api(
          'get',
          `user/wallet/history?page=${number}`
        )
        const {
          data,
          meta: { last_page, current_page }
        } = res.data

        if (res.data.success) {
          this.rechargeHistories = data
          this.totalPages = last_page
          this.currentPage = current_page
        } else {
          this.snack({
            message: this.$i18n.t('something_went_wrong'),
            color: 'red'
          })
        }
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    /**
     * 关闭充值弹窗
     */
    onClose() {
      this.isVisible = false
    },

    /**
     * 充值成功，刷新列表
     */
    onRefresh() {
      this.getList(1)
    },

    approvalStatusColor(approval) {
      switch (approval) {
        case 0:
          return '#000'
          break
        case 1:
          return 'success'
          break
        default:
          return 'error'
          break
      }
    },

    approvalStatusText(approval) {
      switch (approval) {
        case 0:
          return this.$t('pending')
          break
        case 1:
          return this.$t('success')
          break
        default:
          return this.$t('rejected')
          break
      }
    }
  }
}
</script>
